
import { Autocomplete, Box, DialogActions, DialogContent, DialogTitle, FormControl, Grid, Icon, TextField, Typography } from '@mui/material';
import CustomDialog from 'components/custom/CustomDialog';
import { CustomButton } from 'components/roundedbutton/CustomButton';
import { FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import CustomDadosTransportadora from './Venda/CustomDadosTransporte';
import { IAbstractModel } from 'model/abstract.model';
import RegistraVendaService from './registra-venda/registra-venda.service';
import { useSnackbarCustom } from 'hooks/useSnackCustom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setTransportador } from 'redux/features/stateSlice';

type Props = {
    onClose: () => void;
    onConfirm: () => void;
};

const ufs: Array<string> = [
    "AC", "AL", "AM", "AP", "BA",
    "CE", "DF", "ES", "GO", "MA",
    "MG", "MS", "MT", "PA", "PB",
    "PE", "PI", "PR", "RJ", "RN",
    "RO", "RR", "RS", "SC", "SE",
    "SP", "TO"
];

type ModalidadeFrete = {
    codigo: string;
    descricao: string;
};

export type TDadosTransportador = {
    frete: ModalidadeFrete | null,
    transportadora: IAbstractModel | null,
    placa: string | null,
    uf: string | null,
    pesoLiquido: number | null,
    pesoBruto: number | null,
    quantidade: number | null,
    especie: string | null,
    marca: string | null,
    numeroVolumes: string | null,
    valorFrete: number | null,
    valorSeguro: number | null;
    observacao?: string | null;
};

type TInitialState = {
    dadosTransportador: TDadosTransportador,
    options: {
        modalidadesFrete: Array<ModalidadeFrete>;
        transportadoras: Array<IAbstractModel>;
    },
    hasError: {
        frete: boolean;
        valorFrete: boolean;
        valorSeguro: boolean;
        transportadora: boolean;
    },
    filtros: {
        frete: string,
        transportadora: string;
        uf: string;
    };
};

const initialState: TInitialState = {
    dadosTransportador: {
        frete: {
            codigo: "9",
            descricao: "SEM OCORRÊNCIA DE TRANSPORTE"
        },
        transportadora: null,
        placa: null,
        uf: null,
        pesoLiquido: 0,
        pesoBruto: 0,
        quantidade: 0,
        especie: null,
        marca: null,
        numeroVolumes: null,
        valorFrete: 0,
        valorSeguro: 0,
        observacao: null
    },
    options: {
        modalidadesFrete: [
            { codigo: '0', descricao: 'CONTRATAÇÃO POR CONTA DO REMETENTE (CIF)' },
            { codigo: '1', descricao: 'CONTRATAÇÃO POR CONTA DO DESTINATÁRIO (FOB)' },
            { codigo: '2', descricao: 'CONTRATAÇÃO POR CONTA DE TERCEIROS' },
            { codigo: '3', descricao: 'TRANSPORTE PRÓPRIO (REMETENTE)' },
            { codigo: '4', descricao: 'TRANSPORTE PRÓPRIO (DESTINATÁRIO)' },
            { codigo: '9', descricao: 'SEM OCORRÊNCIA DE TRANSPORTE' }
        ],
        transportadoras: []
    },
    hasError: {
        frete: false,
        valorFrete: false,
        valorSeguro: false,
        transportadora: false
    },
    filtros: {
        frete: "",
        transportadora: "",
        uf: ""
    }
};

const DadosTransportador = (props: Props) => {
    const [stateLocal, setStateLocal] = useState(initialState);
    const { addError } = useSnackbarCustom();
    const registraVendaService = RegistraVendaService();
    const dispatch = useAppDispatch();
    const { transportador } = useAppSelector(e => e.state.pedido);
    const tempdadosTransportador = useRef<TDadosTransportador>(initialState.dadosTransportador);

    useEffect(() => {
        registraVendaService.getTransportadoras()
            .then(({ data }) => {
                setStateLocal(prevState => ({
                    ...prevState,
                    options: {
                        ...prevState.options,
                        transportadoras: data
                    }
                }));

                if (transportador.frete && transportador.frete.codigo !== "9") {
                    const frete = stateLocal.options.modalidadesFrete.find(e => e.codigo === transportador.frete?.codigo);
                    const transportadora = stateLocal.options.transportadoras.find(e => e.uuid === transportador.transportadora?.uuid);

                    setStateLocal(prevState => ({
                        ...prevState,
                        dadosTransportador: {
                            especie: transportador.especie ?? prevState.dadosTransportador.especie,
                            marca: transportador.marca ?? prevState.dadosTransportador.marca,
                            numeroVolumes: transportador.numeroVolumes ?? prevState.dadosTransportador.numeroVolumes,
                            pesoBruto: transportador.pesoBruto ?? prevState.dadosTransportador.pesoBruto,
                            pesoLiquido: transportador.pesoLiquido ?? prevState.dadosTransportador.pesoLiquido,
                            placa: transportador.placa ?? prevState.dadosTransportador.placa,
                            quantidade: transportador.quantidade ?? prevState.dadosTransportador.quantidade,
                            uf: transportador.uf ?? prevState.dadosTransportador.uf,
                            valorFrete: transportador.valorFrete ?? prevState.dadosTransportador.valorFrete,
                            valorSeguro: transportador.valorSeguro ?? prevState.dadosTransportador.valorSeguro,
                            observacao: transportador.observacao ?? prevState.dadosTransportador.observacao,
                            frete: frete ?? prevState.dadosTransportador.frete,
                            transportadora: transportadora ?? prevState.dadosTransportador.transportadora
                        }
                    }));
                } else {
                    resetTempDados();
                }
            })
            .catch(err => { });
        return () => {
            resetTempDados();
        };
        //eslint-disable-next-line
    }, []);

    const confirmRef = useRef(false);

    useEffect(() => {
        if (confirmRef.current) {
            props.onConfirm();
        }
        //eslint-disable-next-line
    }, [transportador]);


    const onSetFrete = (frete: ModalidadeFrete) => {
        if (["1", "2", "4", "5"].includes(frete.codigo)) {
            setStateLocal(prevState => ({
                ...prevState,
                dadosTransportador: {
                    ...prevState.dadosTransportador,
                    valorFrete: 0,
                    valorSeguro: 0
                }
            }));
            tempdadosTransportador.current.valorFrete = 0;
            tempdadosTransportador.current.valorSeguro = 0;
        }

        switch (frete.codigo) {
            case "9":
                tempdadosTransportador.current.frete = frete;
                return setStateLocal(prevState => ({
                    ...prevState,
                    dadosTransportador: {
                        frete: frete,
                        especie: initialState.dadosTransportador.especie,
                        marca: initialState.dadosTransportador.marca,
                        numeroVolumes: initialState.dadosTransportador.numeroVolumes,
                        pesoBruto: initialState.dadosTransportador.pesoBruto,
                        pesoLiquido: initialState.dadosTransportador.pesoLiquido,
                        placa: initialState.dadosTransportador.placa,
                        quantidade: initialState.dadosTransportador.quantidade,
                        transportadora: initialState.dadosTransportador.transportadora,
                        uf: initialState.dadosTransportador.uf,
                        valorFrete: initialState.dadosTransportador.valorFrete,
                        valorSeguro: initialState.dadosTransportador.valorSeguro,
                        observacao: initialState.dadosTransportador.observacao
                    }
                }));
            case "3":
            case "4":
                tempdadosTransportador.current.frete = frete;
                return setStateLocal(prevState => ({
                    ...prevState,
                    dadosTransportador: {
                        ...prevState.dadosTransportador,
                        frete: frete,
                        valorFrete: prevState.dadosTransportador.valorFrete,
                        valorSeguro: prevState.dadosTransportador.valorSeguro,
                        transportadora: initialState.dadosTransportador.transportadora
                    }
                }));
            default:
                return setStateLocal(prevState => ({
                    ...prevState,
                    dadosTransportador: {
                        frete: frete,
                        transportadora: tempdadosTransportador.current.transportadora ?? prevState.dadosTransportador.transportadora,
                        placa: tempdadosTransportador.current.placa ?? prevState.dadosTransportador.placa,
                        uf: tempdadosTransportador.current.uf ?? prevState.dadosTransportador.uf,
                        pesoLiquido: tempdadosTransportador.current?.pesoLiquido ?? prevState.dadosTransportador.pesoLiquido,
                        pesoBruto: tempdadosTransportador.current.pesoBruto ?? prevState.dadosTransportador.pesoBruto,
                        quantidade: tempdadosTransportador.current.quantidade ?? prevState.dadosTransportador.quantidade,
                        especie: tempdadosTransportador.current.especie ?? prevState.dadosTransportador.especie,
                        marca: tempdadosTransportador.current.marca ?? prevState.dadosTransportador.marca,
                        numeroVolumes: tempdadosTransportador.current.numeroVolumes ?? prevState.dadosTransportador.numeroVolumes,
                        valorFrete: tempdadosTransportador.current.valorFrete ?? prevState.dadosTransportador.valorFrete,
                        valorSeguro: tempdadosTransportador.current.valorSeguro ?? prevState.dadosTransportador.valorSeguro,
                        observacao: tempdadosTransportador.current.observacao ?? prevState.dadosTransportador.observacao
                    }
                }));
        }
    };

    const onSetTransportadora = useCallback((transportadora: IAbstractModel) => {
        tempdadosTransportador.current.transportadora = transportadora;
        setStateLocal(prevState => ({
            ...prevState,
            dadosTransportador: {
                ...prevState.dadosTransportador,
                transportadora: transportadora
            },
            hasError: {
                ...prevState.hasError,
                transportadora: false
            }
        }));
    }, []);

    const getDisabled = () => {
        if (isSemOcorrenciaTransporte()) return true;
        return !stateLocal.dadosTransportador.frete;
    };


    const validarPlacaMercosul = useCallback((placa: string): boolean => {
        placa = placa.toUpperCase();

        const regexParte1 = /^[A-Z]{0,3}$/;
        const regexParte2 = /^[0-9]{0,1}$/;
        const regexParte4 = /^[0-9]{0,2}$/;

        if (!regexParte1.test(placa.slice(0, 3))) {
            addError({ message: "Os três primeiros caracteres da placa devem ser letras.", preventDuplicate: true });
            return false;
        }
        if (!regexParte2.test(placa.charAt(3))) {
            addError({ message: "O quarto caractere da placa deve ser um número.", preventDuplicate: true });
            return false;
        }
        if (!regexParte4.test(placa.slice(5))) {
            addError({ message: "Os dois últimos caracteres da placa devem ser números.", preventDuplicate: true });
            return false;
        }

        return true;

        //eslint-disable-next-line
    }, []);

    const onSetPlaca = useCallback((placa: string) => {
        if (!validarPlacaMercosul(placa)) return;
        tempdadosTransportador.current.placa = placa;

        setStateLocal(prevState => ({
            ...prevState,
            dadosTransportador: {
                ...prevState.dadosTransportador,
                placa: placa.toUpperCase()
            }
        }));
        //eslint-disable-next-line
    }, []);

    const onSetUF = useCallback((uf: string) => {
        tempdadosTransportador.current.uf = uf;
        setStateLocal(prevState => ({
            ...prevState,
            dadosTransportador: {
                ...prevState.dadosTransportador,
                uf: uf
            }
        }));
        //eslint-disable-next-line
    }, []);

    const onSetPesoLiquido = useCallback((pesoLiquido: string) => {
        if (isNaN(Number(pesoLiquido))) return;
        tempdadosTransportador.current.pesoLiquido = Number(pesoLiquido);
        setStateLocal(prevState => ({
            ...prevState,
            dadosTransportador: {
                ...prevState.dadosTransportador,
                pesoLiquido: Number(pesoLiquido)
            }
        }));
    }, []);

    const onSetPesoBruto = useCallback((pesoBruto: string) => {
        if (isNaN(Number(pesoBruto))) return;
        tempdadosTransportador.current.pesoBruto = Number(pesoBruto);

        setStateLocal(prevState => ({
            ...prevState,
            dadosTransportador: {
                ...prevState.dadosTransportador,
                pesoBruto: Number(pesoBruto)
            }
        }));
    }, []);


    const onSetQuantidade = useCallback((quantidade: string) => {
        if (isNaN(Number(quantidade))) return;
        tempdadosTransportador.current.quantidade = Number(quantidade);

        setStateLocal(prevState => ({
            ...prevState,
            dadosTransportador: {
                ...prevState.dadosTransportador,
                quantidade: Number(quantidade)
            }
        }));
    }, []);

    const onSetEspecie = useCallback((especie: string) => {
        tempdadosTransportador.current.especie = especie;

        setStateLocal(prevState => ({
            ...prevState,
            dadosTransportador: {
                ...prevState.dadosTransportador,
                especie: especie
            }
        }));
    }, []);

    const onSetMarca = useCallback((marca: string) => {
        tempdadosTransportador.current.marca = marca;

        setStateLocal(prevState => ({
            ...prevState,
            dadosTransportador: {
                ...prevState.dadosTransportador,
                marca: marca
            }
        }));
    }, []);

    const onSetNumeroVolumes = useCallback((numeroVolumes: string) => {
        if (isNaN(Number(numeroVolumes))) return;
        tempdadosTransportador.current.numeroVolumes = numeroVolumes;

        setStateLocal(prevState => ({
            ...prevState,
            dadosTransportador: {
                ...prevState.dadosTransportador,
                numeroVolumes: numeroVolumes
            }
        }));
    }, []);

    const isCif = () => {
        return stateLocal.dadosTransportador.frete?.codigo === "0";
    };

    const isFob = () => {
        return stateLocal.dadosTransportador.frete?.codigo === "1";
    };

    const isContratacaoTerceiros = () => {
        return stateLocal.dadosTransportador.frete?.codigo === "2";
    };

    const isTransporteProprioRemetente = () => {
        return stateLocal.dadosTransportador.frete?.codigo === "3";
    };

    const isTransporteProprioDestinatario = () => {
        return stateLocal.dadosTransportador.frete?.codigo === "4";
    };

    const isSemOcorrenciaTransporte = () => {
        return stateLocal.dadosTransportador.frete?.codigo === "9";
    };

    const onSetValorFrete = useCallback((valorFrete: number) => {
        tempdadosTransportador.current.valorFrete = valorFrete;

        setStateLocal(prevState => ({
            ...prevState,
            dadosTransportador: {
                ...prevState.dadosTransportador,
                valorFrete: valorFrete
            },
            hasError: {
                ...prevState.hasError,
                valorFrete: false
            }
        }));
    }, []);

    const onSetValorSeguro = useCallback((valorSeguro: number) => {
        tempdadosTransportador.current.valorSeguro = valorSeguro;

        setStateLocal(prevState => ({
            ...prevState,
            dadosTransportador: {
                ...prevState.dadosTransportador,
                valorSeguro: valorSeguro
            },
            hasError: {
                ...prevState.hasError,
                valorSeguro: false
            }
        }));
    }, []);

    const onSetObservacao = useCallback((observacao: string) => {
        setStateLocal(prevState => ({
            ...prevState,
            dadosTransportador: {
                ...prevState.dadosTransportador,
                observacao: observacao || null
            }
        }));
    }, []);

    const onConfirm = (e: FormEvent) => {
        e.preventDefault();

        const { frete, transportadora } = stateLocal.dadosTransportador;

        if (!frete || (!transportadora && (isCif() || isFob() || isContratacaoTerceiros()))) {
            setStateLocal(prevState => ({ ...prevState, hasError: { ...prevState.hasError, frete: true } }));
            return addError({ message: "Preencha o formulário corretamente", closeable: true, id: "form-incorrect-error" });
        }
        dispatch(setTransportador(stateLocal.dadosTransportador));
        resetTempDados();
        confirmRef.current = true;
    };

    const getFreteOptions = useCallback((modalidadesFrete: Array<ModalidadeFrete>, filtro: string) => {
        if (filtro) {
            return modalidadesFrete.filter(e => e.descricao.includes(filtro.toUpperCase()));
        }
        return modalidadesFrete;
    }, []);

    const getOptionsTransportadora = useCallback((transportadora: Array<IAbstractModel>, filtro: string) => {
        if (filtro) {
            return transportadora.filter(e => e.descritivo.includes(filtro.toUpperCase()));
        }
        return transportadora;
    }, []);

    const getOptionsUfs = useCallback((ufs: Array<string>, filtro: string) => {
        if (filtro) {
            return ufs.filter(e => e.includes(filtro.toUpperCase()));
        }
        return ufs;
    }, []);

    const resetTempDados = useCallback(() => {
        tempdadosTransportador.current = {
            frete: {
                codigo: "9",
                descricao: "SEM OCORRÊNCIA DE TRANSPORTE"
            },
            transportadora: null,
            placa: null,
            uf: null,
            pesoLiquido: 0,
            pesoBruto: 0,
            quantidade: 0,
            especie: null,
            marca: null,
            numeroVolumes: null,
            valorFrete: 0,
            valorSeguro: 0,
            observacao: null
        };
        setStateLocal(prevState => ({
            ...prevState,
            dadosTransportador: tempdadosTransportador.current
        }));
    }, []);

    return (
        <CustomDialog
            id="dados-transportadora-dialog"
            open={true}
            fullWidth={true}
            maxWidth={'lg'}
            onClose={props.onClose}
        >

            <form onSubmit={onConfirm} style={{ gap: 0 }}>
                <DialogTitle id="dados-transpotadora-dialog-title" textAlign={'center'}>
                    <Box sx={{
                        width: '100%',
                        borderTop: '1px solid rgba(0, 0, 0, .1)',
                        borderBottom: '1px solid rgba(0, 0, 0, .1)',
                        padding: '15px 0',
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                        <Box id='dados-transportadora-box' sx={{ width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                            <CustomDadosTransportadora fontSize="45px" color="#666666" id="dados-transportadora-icon" />
                            <Typography id='dados-transportadora-icon-text' sx={{ paddingLeft: '25px', fontSize: '45px', fontWeight: '600', color: '#666666', userSelect: 'none' }}>Dados do transportador</Typography>
                        </Box>
                    </Box>

                </DialogTitle>

                <DialogContent id="dados-transportadora-dialog-content" sx={{ display: 'flex', flexDirection: 'column', gap: '15px', alignItems: 'center' }}>
                    <Grid container spacing={2}>

                        {/* FRETE */}
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth variant="standard" error={stateLocal.hasError.frete}>
                                <Autocomplete
                                    value={stateLocal.dadosTransportador.frete}
                                    id="acFrete"
                                    options={getFreteOptions(stateLocal.options.modalidadesFrete, stateLocal.filtros.frete)}
                                    getOptionLabel={(option) => option.descricao}
                                    onInputChange={(e, value) => {
                                        setStateLocal(prevState => ({
                                            ...prevState,
                                            filtros: {
                                                ...prevState.filtros,
                                                frete: value
                                            }
                                        }));
                                    }}
                                    filterOptions={(x) => x}
                                    noOptionsText={'Nenhum resultado encontrado'}
                                    onChange={(e, frete) => {
                                        if (frete) {
                                            onSetFrete(frete);
                                        }
                                    }}
                                    isOptionEqualToValue={(option, value) => option.codigo === value.codigo}
                                    renderInput={
                                        (params) => <TextField
                                            {...params}
                                            label="Frete *"
                                            variant="standard"
                                        />}
                                />
                            </FormControl>
                        </Grid>

                        {/* TRANSPORTADORA */}
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth variant="standard" error={stateLocal.hasError.frete}>
                                <Autocomplete
                                    disabled={getDisabled() || isTransporteProprioRemetente() || isTransporteProprioDestinatario() || isSemOcorrenciaTransporte()}
                                    value={stateLocal.dadosTransportador.transportadora}
                                    id="acTransportadora"
                                    options={getOptionsTransportadora(stateLocal.options.transportadoras, stateLocal.filtros.transportadora)}
                                    getOptionLabel={(option) => option.descritivo}
                                    onInputChange={(e, value) => {
                                        setStateLocal(prevState => ({
                                            ...prevState,
                                            filtros: {
                                                ...prevState.filtros,
                                                transportadora: value
                                            }
                                        }));
                                    }}
                                    filterOptions={(x) => x}
                                    noOptionsText={'Nenhum resultado encontrado'}
                                    onChange={(e, transportadora) => {
                                        if (transportadora) {
                                            onSetTransportadora(transportadora);
                                        }
                                    }}
                                    isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
                                    renderInput={
                                        (params) => <TextField
                                            {...params}
                                            label={`Transportadora ${getDisabled() || isTransporteProprioRemetente() || isTransporteProprioDestinatario() || isSemOcorrenciaTransporte() ? "" : "*"}`}
                                            variant="standard"
                                        />}
                                />
                            </FormControl>
                        </Grid>

                        {/* Placa */}
                        <Grid item xs={12} md={3}>
                            <FormControl fullWidth variant="standard" error={false}>
                                <TextField
                                    id={`dados-transporte-input`}
                                    fullWidth={true}
                                    variant="standard"
                                    label="Placa"
                                    inputProps={{ maxLength: 7 }}
                                    onChange={(e) => onSetPlaca(e.target.value)}
                                    value={stateLocal.dadosTransportador.placa ?? ""}
                                    disabled={getDisabled()}
                                />

                            </FormControl>
                        </Grid>

                        {/* UF */}
                        <Grid item xs={12} md={3}>
                            <FormControl fullWidth variant="standard" error={false}>
                                <Autocomplete
                                    disabled={getDisabled()}
                                    value={stateLocal.dadosTransportador.uf}
                                    id="acUF"
                                    options={getOptionsUfs(ufs, stateLocal.filtros.uf)}
                                    getOptionLabel={(option) => option}
                                    onInputChange={(e, value) => {
                                        setStateLocal(prevState => ({
                                            ...prevState,
                                            filtros: {
                                                ...prevState.filtros,
                                                uf: value
                                            }
                                        }));
                                    }}
                                    filterOptions={(x) => x}
                                    noOptionsText={'Nenhum resultado encontrado'}
                                    onChange={(e, uf) => {
                                        if (uf) {
                                            onSetUF(uf);
                                        }
                                    }}
                                    isOptionEqualToValue={(option, value) => option === value}
                                    renderInput={
                                        (params) => <TextField
                                            {...params}
                                            label={`UF`}
                                            variant="standard"
                                        />}
                                />
                            </FormControl>
                        </Grid>

                        {/* Peso Liquido */}
                        <Grid item xs={12} md={3}>
                            <FormControl fullWidth variant="standard" error={false}>
                                <NumericFormat
                                    decimalScale={3}
                                    id={`dados-transportadora-peso-liquido`}
                                    label="Peso líquido do volume"
                                    fixedDecimalScale
                                    defaultValue={0}
                                    allowedDecimalSeparators={[',']}
                                    customInput={TextField}
                                    decimalSeparator=","
                                    suffix={' Kg'}
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator="."
                                    variant="standard"
                                    disabled={getDisabled()}
                                    allowNegative={false}
                                    value={stateLocal.dadosTransportador.pesoLiquido}
                                    onValueChange={({ floatValue }) => {
                                        onSetPesoLiquido(floatValue?.toString() ?? "0")
                                    }}
                                />

                            </FormControl>
                        </Grid>

                        {/* Peso Bruto */}
                        <Grid item xs={12} md={3}>
                            <FormControl fullWidth variant="standard" error={false}>
                                <NumericFormat
                                    decimalScale={3}
                                    id={`dados-transportadora-peso-bruto`}
                                    label="Peso bruto do volume"
                                    fixedDecimalScale
                                    defaultValue={0}
                                    allowedDecimalSeparators={[',']}
                                    customInput={TextField}
                                    decimalSeparator=","
                                    suffix={' Kg'}
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator="."
                                    variant="standard"
                                    disabled={getDisabled()}
                                    allowNegative={false}
                                    value={stateLocal.dadosTransportador.pesoBruto}
                                    onValueChange={({ floatValue }) => {
                                        onSetPesoBruto(floatValue?.toString() ?? "0")
                                    }}
                                />

                            </FormControl>
                        </Grid>

                        {/* Quantidade */}
                        <Grid item xs={12} md={3}>
                            <FormControl fullWidth variant="standard" error={false}>
                                <TextField
                                    disabled={getDisabled()}
                                    id={`dados-transportadora-quantidade`}
                                    fullWidth={true}
                                    variant="standard"
                                    label="Quantidade de volumes"
                                    onChange={(e) => onSetQuantidade(e.target.value)}
                                    value={stateLocal.dadosTransportador.quantidade ?? 0}
                                />

                            </FormControl>
                        </Grid>

                        {/* Espécie */}
                        <Grid item xs={12} md={3}>
                            <FormControl fullWidth variant="standard" error={false}>
                                <TextField
                                    disabled={getDisabled()}
                                    id={`dados-transportadora-especie`}
                                    fullWidth={true}
                                    variant="standard"
                                    label="Espécie dos volumes"
                                    inputProps={{ maxLength: 60 }}
                                    onChange={(e) => onSetEspecie(e.target.value)}
                                    value={stateLocal.dadosTransportador.especie ?? ""}
                                />

                            </FormControl>
                        </Grid>

                        {/* Marca */}
                        <Grid item xs={12} md={3}>
                            <FormControl fullWidth variant="standard" error={false}>
                                <TextField
                                    disabled={getDisabled()}
                                    id={`dados-transportadora-marca`}
                                    fullWidth={true}
                                    variant="standard"
                                    label="Marca dos volumes"
                                    inputProps={{ maxLength: 60 }}
                                    onChange={(e) => onSetMarca(e.target.value)}
                                    value={stateLocal.dadosTransportador.marca ?? ""}
                                />

                            </FormControl>
                        </Grid>

                        {/* Número de volumes */}
                        <Grid item xs={12} md={3}>
                            <FormControl fullWidth variant="standard" error={false}>
                                <TextField
                                    disabled={getDisabled()}
                                    id={`dados-transportadora-numero-volumes`}
                                    fullWidth={true}
                                    variant="standard"
                                    label="Número de volumes"
                                    inputProps={{ maxLength: 60 }}
                                    onChange={(e) => onSetNumeroVolumes(e.target.value)}
                                    value={stateLocal.dadosTransportador.numeroVolumes ?? ""}
                                />

                            </FormControl>
                        </Grid>

                        {/* Valor Frete */}
                        <Grid item xs={12} md={3}>
                            <FormControl fullWidth variant="standard" error={false}>
                                <NumericFormat
                                    disabled={getDisabled() || isFob() || isContratacaoTerceiros() || isTransporteProprioDestinatario()}
                                    label={`Valor do frete ${isCif() || isTransporteProprioRemetente() ? " *" : ""}`}
                                    decimalScale={2}
                                    fixedDecimalScale
                                    defaultValue={0}
                                    error={stateLocal.hasError.valorFrete}
                                    allowedDecimalSeparators={[',']}
                                    customInput={TextField}
                                    decimalSeparator=","
                                    prefix={'R$ '}
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator="."
                                    variant="standard"
                                    allowNegative={false}
                                    value={stateLocal.dadosTransportador.valorFrete ?? 0}
                                    onValueChange={({ floatValue }) => onSetValorFrete(floatValue ?? 0)}
                                />

                            </FormControl>
                        </Grid>

                        {/* Valor Seguro */}
                        <Grid item xs={12} md={3}>
                            <FormControl fullWidth variant="standard">
                                <NumericFormat
                                    disabled={getDisabled() || isFob() || isContratacaoTerceiros() || isTransporteProprioDestinatario()}
                                    label={`Valor do seguro ${isCif() || isTransporteProprioRemetente() ? " *" : ""}`}
                                    decimalScale={2}
                                    fixedDecimalScale
                                    defaultValue={0}
                                    error={stateLocal.hasError.valorSeguro}
                                    allowedDecimalSeparators={[',']}
                                    customInput={TextField}
                                    decimalSeparator=","
                                    prefix={'R$ '}
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator="."
                                    variant="standard"
                                    allowNegative={false}
                                    value={stateLocal.dadosTransportador.valorSeguro ?? 0}
                                    onValueChange={({ floatValue }) => onSetValorSeguro(floatValue ?? 0)}
                                />

                            </FormControl>
                        </Grid>
                    </Grid>

                    <TextField sx={{ marginTop: "20px", marginBottom: "20px" }}
                        label="Observação"
                        multiline
                        fullWidth
                        value={stateLocal.dadosTransportador.observacao ?? ""}
                        id="dados-transportador-observacao"
                        onChange={(e) => onSetObservacao(e.target.value)}
                        inputProps={{ maxLength: 200 }}
                        color='warning'
                        focused
                    />

                </DialogContent >

                <DialogActions
                    sx={{
                        justifyContent: 'space-between'
                    }}>
                    <CustomButton onClick={props.onClose} variant="contained" color="error" startIcon={<Icon className='fa-solid fa-xmark' />}>
                        Cancelar
                    </CustomButton>
                    <CustomButton type="submit" variant="contained" color="success" startIcon={<Icon className='fa-solid fa-check' />}>
                        Confirmar
                    </CustomButton>
                </DialogActions>
            </form >
        </CustomDialog >
    );
};

export default DadosTransportador;
