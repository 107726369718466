import { DialogTitle, Typography, DialogContent, Grid, DialogActions, Icon, Autocomplete, Box, Chip, IconButton, TextField, Checkbox, ListItemText, MenuItem, AutocompleteInputChangeReason } from "@mui/material";
import CustomDialog from "components/custom/CustomDialog";
import { CustomButton } from "components/roundedbutton/CustomButton";
import CloseIcon from '@mui/icons-material/Close';
import { IAbstractModel } from "model/abstract.model";
import { FormEvent, useCallback, useEffect, useState } from "react";
import RegistraVendaService from "./registra-venda.service";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setVendedores } from "redux/features/stateSlice";
import { useSnackbarCustom } from "hooks/useSnackCustom";

type TOutrosComissionadosProps = {
    onClose: () => void;
};

type TOutrosComissionadosInitialState = {
    vendedoresSelected: Array<IAbstractModel>,
    vendedoresOptions: Array<IAbstractModel>;
};

const initialState: TOutrosComissionadosInitialState = {
    vendedoresSelected: [],
    vendedoresOptions: []
};

const OutrosComissionados = (props: TOutrosComissionadosProps) => {
    const [stateLocal, setStateLocal] = useState(initialState);
    const service = RegistraVendaService();
    const { vendedores } = useAppSelector(e => e.state.pedido);
    const dispatch = useAppDispatch();
    const { addError } = useSnackbarCustom();

    const getOptions = (option: string, reason: AutocompleteInputChangeReason | null) => {
        if (reason && reason === "clear") return setStateLocal(prevState => ({ ...prevState, vendedoresSelected: [] }))

        service.getVendedoresAc(option)
            .then(({ data }) => {
                const result = data.filter(option => !vendedores.some(vendedor => vendedor.uuid === option.uuid));
                setStateLocal(prevState => ({ ...prevState, vendedoresOptions: result }));
            }).catch(error => {
                addError({ message: error.response.data.extra[0], closeable: true });
            });
    };

    useEffect(() => {
        setStateLocal(prevState => ({ ...prevState, vendedoresSelected: vendedores }));
        getOptions("", null);
        //eslint-disable-next-line
    }, []);

    const onSelectOption = (uuid: string) => {
        const isInVendedoresSelected = stateLocal.vendedoresSelected.find(e => e.uuid === uuid);
        if (isInVendedoresSelected) {
            return setStateLocal(prevState => ({ ...prevState, vendedoresSelected: prevState.vendedoresSelected.filter(e => e.uuid !== uuid) }));
        }

        const find = stateLocal.vendedoresOptions.find(e => e.uuid === uuid);
        if (find) {
            setStateLocal(prevState => ({ ...prevState, vendedoresSelected: [...prevState.vendedoresSelected, find] }));
        }
    };


    const renderOptions = (option: IAbstractModel) => {
        return (
            <MenuItem
                onClick={onSelectOption.bind(null, option.uuid)}
                key={option.uuid}
                value={option.descritivo}>
                <Checkbox
                    id={`item-${option.descritivo?.toLowerCase()}-input`}
                    sx={{
                        '&.MuiCheckbox-root.Mui-checked': {
                            color: 'var(--laranja)'
                        }
                    }}
                    checked={stateLocal.vendedoresSelected.some(e => e.uuid === option.uuid)}
                />
                <ListItemText primary={option.descritivo} />
            </MenuItem>
        );
    };

    const onConfirm = useCallback((e: FormEvent) => {
        e.preventDefault();
        const toInsert = stateLocal.vendedoresSelected.filter(e => !vendedores.some(vendedor => e.uuid === vendedor.uuid));
        dispatch(setVendedores([...vendedores, ...toInsert]));
        props.onClose();
        //eslint-disable-next-line
    }, [stateLocal.vendedoresSelected]);

    const onRemoveChip = useCallback((uuid: string) => {
        setStateLocal(prevState => ({ ...prevState, vendedoresSelected: prevState.vendedoresSelected.filter(e => e.uuid !== uuid) }));
        const result = vendedores.filter(e => e.uuid !== uuid);
        dispatch(setVendedores(result));
        //eslint-disable-next-line
    }, []);

    return (
        <CustomDialog
            id="cadastro-pessoa-dialog"
            open={true}
            fullWidth={true}
            maxWidth={'lg'}
            onClose={props.onClose}
        >
            <form onSubmit={onConfirm} style={{ gap: 0 }}>
                <DialogTitle id="dialog-title" textAlign={'center'}>
                    <Typography fontSize={30}>Selecione múltiplos vendedores</Typography>
                </DialogTitle>

                <DialogContent id="dialog-content" sx={{ display: 'flex', flexDirection: 'column', gap: '15px', alignItems: 'center', minHeight: "300px", maxHeight: "500px" }}>
                    <Grid container spacing={2}>
                        <Autocomplete
                            sx={{ overflowX: 'hidden', overflowY: 'auto', width: '100%' }}
                            multiple
                            filterSelectedOptions
                            value={stateLocal.vendedoresSelected}
                            options={stateLocal.vendedoresOptions}
                            getOptionLabel={(option) => option.descritivo}
                            onInputChange={(_, value, reason) => getOptions(value, reason)}
                            noOptionsText="Nenhum valor encontrado"
                            isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Vendedores"
                                />)}
                            renderOption={(_props, option, _state, _ownerState) => renderOptions(option)}
                            renderTags={(p) => p.map((e, i) => {
                                return (
                                    <Box key={e.descritivo}
                                        sx={{
                                            backgroundColor: i === 0 ? 'var(--laranja)' : "#D7D7D7",
                                            color: '#fff',
                                            borderRadius: '50px',
                                            marginRight: '5px',
                                            marginTop: '2px',
                                            marginBottom: '2px',
                                        }}
                                    >
                                        <Chip
                                            key={e.uuid}
                                            sx={{
                                                height: '1.5rem',
                                                backgroundColor: i === 0 ? 'var(--laranja)' : "#D7D7D7",
                                                color: i === 0 ? '#fff' : "#000"
                                            }}
                                            label={e.descritivo.split(" - ")[1]}
                                        />
                                        <IconButton
                                            onClick={onRemoveChip.bind(null, e.uuid)}
                                            sx={{
                                                color: i === 0 ? '#fff' : "#000",
                                                padding: 0,
                                                paddingRight: "2px",
                                                fontSize: '15px'
                                            }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                );
                            })}
                        />
                    </Grid>
                </DialogContent >

                <DialogActions
                    sx={{
                        justifyContent: 'space-between'
                    }}>
                    <CustomButton onClick={props.onClose} variant="contained" color="error" startIcon={<Icon className='fa-solid fa-xmark' />}>
                        Cancelar
                    </CustomButton>
                    <CustomButton type="submit" disabled={false} variant="contained" color="success" startIcon={<Icon className='fa-solid fa-check' />}>
                        Confirmar
                    </CustomButton>
                </DialogActions>
            </form >
        </CustomDialog >
    );
};

export default OutrosComissionados;
