import { CircularProgress, Menu, Skeleton, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbarCustom } from "hooks/useSnackCustom";
import { IAbstractModel } from "model/abstract.model";
import { useCallback, useRef, useState } from "react";
import { convertRelatorioDate, currencyOf } from "utils/util";
import Ellipsis from "./Ellipsis";
import CancelaPedidoDialog from "./Header/CancelaPedidoDialog";
import CustomLoading from "./Header/CustomLoading";
import EditaVendedor from "./Header/EditaVendedor";
import MenuItemAberto from "./Header/MenuItemAberto";
import MenuItemCancelado from "./Header/MenuItemCancelado";
import MenuItemFaturado from "./Header/MenuItemFaturado";
import TableCellWithTooltip from "./Header/TableCellWithTooltip";
import CancelaDFeDialog from "./dialogs/CancelaDFeDialog";
import VendaService from "./venda.service";
import { Assosicacao, ColumnsRelatorioVendas, ItemRelatorioVendas, StatusNotaFiscal, StatusPedido, TVendasTableProps, getAssociacao } from "./vendas.model";
import { useAppSelector } from "redux/hooks";

const relatorioColumnsLabels: ColumnsRelatorioVendas = [
    { sizePercent: '5', key: 'numeroPedido', label: 'Pedido', },
    { sizePercent: '12', key: 'cliente', label: 'Cliente', },
    { sizePercent: '12', key: 'vendedor', label: 'Vendedor', },
    { sizePercent: '8', key: 'emissao', label: 'Emissão', type: 'date' },
    { sizePercent: '7', key: 'valorPedido', label: 'Total do pedido', type: 'currency' },
    { sizePercent: '4', key: 'serie', label: 'Serie', },
    { sizePercent: '3', key: 'numeroNotaFiscal', label: 'Nota', },
    { sizePercent: '7', key: 'valorNota', label: 'Total da nota', type: 'currency' },
    { sizePercent: '5', key: 'modelo', label: 'Modelo', },
    { sizePercent: '7', key: 'statusPedido', label: 'Status pedido' },
    {
        sizePercent: '5',
        key: 'valorItensTroca',
        label: '',
        type: 'icon',
        iconOpt: {
            className: 'fa-solid fa-retweet',
            color: 'green',
            sizePx: 18,
        },
        tooltipOpt: {
            content: '',
            position: 'right'
        }
    },
    { sizePercent: '2', key: 'acao', label: '', },
];

const initialState = {
    showCancelarModal: false,
    showCancelarDFeDialog: false,
    loading: false,
    showEditarVendedor: false,
    isChangingVendedor: false
};

const VendasTable = (props: TVendasTableProps) => {
    const [stateLocal, setStateLocal] = useState(initialState);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openMenuRow = Boolean(anchorEl);
    const selectedItemref = useRef<ItemRelatorioVendas | null>(null);
    const vendasService = VendaService();
    const { addSuccess, addError } = useSnackbarCustom();
    const estabelecimento = useAppSelector(e => e.estabelecimentoSelecionado)

    const onClickEllipsis = (event: React.MouseEvent<HTMLButtonElement>, item: ItemRelatorioVendas) => {
        if (item.statusPedido === StatusPedido.ABERTO.toUpperCase()
            || item.statusPedido === StatusPedido.FATURADO.toUpperCase()
        ) { // descomentar o if em futuras versões (Atual 2.30.x)
            selectedItemref.current = item;
            setAnchorEl(event.currentTarget);
        }
    };

    const onCloseMenuRow = () => {
        setAnchorEl(null);
        setTimeout(() => {
            selectedItemref.current = null;
        }, 100);
    };

    const onOpen = (key: keyof typeof initialState) => {
        setStateLocal(prevState => ({ ...prevState, [key]: true }));
    };

    const imprimirDFE = useCallback((uuid: string) => {
        setStateLocal(prevState => ({ ...prevState, loading: true }));
        onCloseMenuRow();
        vendasService.getUrlDanfe(uuid)
            .then(({ data }: { data: string; }) => {
                setStateLocal(prevState => ({ ...prevState, loading: false }));
                props.onShowImprimirDFE!(data);
            })
            .catch(() => {
                setStateLocal(prevState => ({ ...prevState, loading: false }));
            });
        // eslint-disable-next-line
    }, []);

    const excluirNota = useCallback((chaveAcesso: string) => {
        setStateLocal(prevState => ({ ...prevState, loading: true }));
        onCloseMenuRow();
        vendasService.excluirNota(chaveAcesso)
            .then(() => {
                setStateLocal(prevState => ({ ...prevState, loading: false }));
                addSuccess({ message: 'Nota excluída com sucesso', closeable: true });
            })
            .catch(() => {
                addError({ message: 'Não foi possível excluir a nota', closeable: true });
            })
            .finally(() => {
                props.onAtualizarRelatorio();
                setStateLocal(prevState => ({ ...prevState, loading: false }));
            });
        // eslint-disable-next-line
    }, []);

    const onClose = useCallback((key: keyof typeof initialState) => {
        onCloseMenuRow();
        setStateLocal(prevState => ({ ...prevState, [key]: false }));
    }, []);

    const getCor = useCallback((status: string) => {
        const key = Object.keys(Assosicacao).find(e => getAssociacao(e).statusNotaFiscal === status);
        return getAssociacao(key ?? "")?.rgb;
    }, []);

    const isValidButton = useCallback((item: ItemRelatorioVendas) => {
        // Atualizar ao mexer nas ações do relatorio, até um ponto que não haverá
        // necessidade dessa função, pois todos statusNotaFiscal terão suas devidas ações

        if (item.statusPedido === StatusPedido.FATURADO.toUpperCase()) {
            return item.statusNotaFiscal !== StatusNotaFiscal.CANCELADA;
        }
        if (item.statusPedido === StatusPedido.ABERTO.toUpperCase()) {
            return true;
        }
        if (item.statusPedido === StatusPedido.CANCELADO.toUpperCase()) {
            return false;
        }
        return false;
    }, []);

    const imprimirPedidoA4 = useCallback((uuid: string) => {
        onCloseMenuRow();
        props.onShowImprimirA4!(uuid);
        //eslint-disable-next-line
    }, [estabelecimento.uuid]);

    const imprimirCrediario = useCallback((uuid: string, emissaoNota: Date | undefined) => {
        onCloseMenuRow();
        props.onImprimirCrediario!(uuid, emissaoNota);
        //eslint-disable-next-line
    }, [estabelecimento.uuid]);

    const onImprimirPreVenda = useCallback((itemRelatorio: ItemRelatorioVendas) => {
        onCloseMenuRow();
        props.onImprimirPreVenda!(itemRelatorio);
        //eslint-disable-next-line
    }, [estabelecimento.uuid]);

    const imprimirPedido = useCallback((uuid: string) => {
        onCloseMenuRow();
        props.onShowImprimirOutros!(uuid);
        // eslint-disable-next-line
    }, [estabelecimento.uuid]);

    const editarVendedor = useCallback(() => {
        setStateLocal(prevState => ({ ...prevState, showEditarVendedor: true }));
        setAnchorEl(null);
        // eslint-disable-next-line
    }, [estabelecimento.uuid]);

    const navigatePdv = (uuid: string, numeroPedido: number) => {
        onCloseMenuRow();
        props.onNavegaPDV(uuid, numeroPedido);
    };

    const navigatePreVenda = (uuid: string, numeroPedido: number) => {
        onCloseMenuRow();
        props.onNavegaPreVenda!(uuid, numeroPedido);
    };

    const onEditaVendedor = useCallback((vendedor: IAbstractModel) => {
        setStateLocal(prevState => ({ ...prevState, isChangingVendedor: true }));
        vendasService.editarVendedor(vendedor.uuid, selectedItemref.current!.uuidPedido)
            .then(() => {
                props.onAtualizarRelatorio();
                setStateLocal(prevState => ({ ...prevState, showEditarVendedor: false, isChangingVendedor: false }));
            })
            .catch(() => {
                setStateLocal(prevState => ({ ...prevState, showEditarVendedor: false, isChangingVendedor: false }));
            });
        // eslint-disable-next-line
    }, [selectedItemref.current]);

    const onVisualizar = useCallback((uuid: string, numeroPedido: number) => {
        onCloseMenuRow();
        props.onVisualizar(uuid, numeroPedido);
        //eslint-disable-next-line
    }, [estabelecimento.uuid])

    const downloadXML = (chaveAcesso: string) => {
        setStateLocal(prevState => ({ ...prevState, loading: true }));
        onCloseMenuRow();
        vendasService.baixarXML(chaveAcesso)
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${chaveAcesso}.xml`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                addSuccess({ message: 'Download realizado com sucesso', closeable: true });
            })
            .catch(() => {
                addError({ message: 'Não foi possível baixar o XML da nota', closeable: true });
            })
            .finally(() => {
                props.onAtualizarRelatorio();
                setStateLocal(prevState => ({ ...prevState, loading: false }));
            });
    };

    return (
        <>
            <TableContainer sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: 1,
                position: 'relative',
                boxSizing: 'border-box',
                //height: '100%'
                height: '500px',
                marginTop: '10px'
            }}
                key="vendasTable_table_container"
                className="color-orange">
                {props.isLoading &&
                    <Box sx={{ position: 'absolute', width: '100%', height: 'calc(100%)', bgcolor: 'rgba(0,0,0,0.05)', border: 0, padding: 0, zIndex: props.isLoading ? 100 : 1 }}>
                        <CircularProgress
                            size={36}
                            color='warning'
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                        />
                    </Box>
                }
                <Table
                    id="content-table_vendas_table"
                    stickyHeader
                    size="small"
                    key="content-table_vendas_table"
                >
                    <TableHead key="header_table_vendas_table" id="header_table_vendas_table">
                        <TableRow key="header_row_table_vendas_table" id="header_row_table_vendas_table">
                            {relatorioColumnsLabels.map((header) => (
                                <TableCell
                                    sx={{ whiteSpace: 'nowrap', width: `${header.sizePercent}%`, textAlign: 'center', fontWeight: 'bold' }}
                                    key={`header_cell_table_${header.key}`}
                                    id={`header_cell_table_${header.key}`}
                                >
                                    {header.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody key="header_table_body">
                        {props.totalizadores.length === 0 && !props.totalizadoresError &&
                            Array.from([].constructor(7)).map((unknown, indexA) => (
                                <TableRow key={`body_table_${unknown}_${indexA}`}>
                                    {relatorioColumnsLabels.map((a, indexB) => {
                                        return <TableCell
                                            key={`cell_table_${a.key}_${indexB}`}
                                        >
                                            <Skeleton
                                                animation="wave"
                                                variant="rounded"
                                                width={'100%'}
                                                height={30}
                                            />
                                        </TableCell>;
                                    })}
                                </TableRow>
                            ))
                        }
                        {props.totalizadores.length === 0 && props.totalizadoresError &&
                            <TableRow sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                position: 'absolute',
                                top: '200px'
                            }}
                                key="sem_pedidos_table_row_error"
                            >
                                <TableCell
                                    key="row_sem_pedidos_table_cell_error"
                                    colSpan={11}
                                    sx={{
                                        fontSize: '48px',
                                        color: "#E4E4E4",
                                        border: 0,
                                        padding: 0,
                                        userSelect: 'none'
                                    }}>Não há pedidos no período informado.</TableCell>
                            </TableRow>
                        }
                        {props.totalizadores.length > 0 && props.totalizadores.map((item, indexA) => {
                            return (
                                <TableRow key={item.numeroPedido.toString()} style={{ backgroundColor: getCor(item.statusNotaFiscal) }}>
                                    {relatorioColumnsLabels.map((a, indexB) => {
                                        let valor: any = item[a.key];
                                        if (a.type === 'date') valor = convertRelatorioDate(valor.toLocaleString('pt-BR'));
                                        if (a.type === 'currency') valor = currencyOf(valor);
                                        let troca = {
                                            valor: item.valorItensTroca,
                                            nota: item.numeroNotaFiscalTroca,
                                            serie: item.serieTroca
                                        };
                                        return (
                                            <TableCell
                                                sx={{ textAlign: 'center', position: 'relative' }} id={`item_${indexB + 1}_row_${indexA}_${a.label}`}
                                                key={`tableCell_${a.key}_${indexB}`}
                                            >
                                                {a.key === 'acao' &&
                                                    <Ellipsis
                                                        index={indexA}
                                                        onClick={onClickEllipsis}
                                                        item={item}
                                                        isValidButton={isValidButton}
                                                    />}

                                                {(a.key === "cliente" || a.key === 'vendedor') &&
                                                    <TableCellWithTooltip
                                                        content={valor ?? (a.key === 'cliente' ? 'CONSUMIDOR' : '')}
                                                        index={indexA}
                                                        tooltipPosition={indexA <= 4 ? 'bottom' : 'top'}
                                                    />}

                                                {a.type === "icon" && troca.valor > 0 &&
                                                    <TableCellWithTooltip
                                                        content={`${troca.serie}/${troca.nota} - ${currencyOf(troca.valor)}`}
                                                        index={indexA}
                                                        isIcon
                                                        iconOpt={a.iconOpt}
                                                        tooltipPosition={indexA <= 4 ? 'bottom' : 'top'}
                                                    />}

                                                {(a.key !== 'vendedor' && a.key !== 'cliente' && a.type !== 'icon') &&
                                                    valor
                                                }
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            )
                        })}

                        <Menu key="vendas_table_menu"
                            anchorEl={anchorEl}
                            open={openMenuRow}
                            onClose={onCloseMenuRow}
                        >
                            {selectedItemref.current?.statusPedido === StatusPedido.FATURADO.toUpperCase() &&
                                <MenuItemFaturado
                                    itemRef={selectedItemref}
                                    onOpenCancelarDFe={() => onOpen('showCancelarDFeDialog')}
                                    onImprimirDFE={imprimirDFE}
                                    onImprimirPedido={imprimirPedido}
                                    onExcluirNota={excluirNota}
                                    onEditarVendedor={editarVendedor}
                                    onImprimirPedidoA4={imprimirPedidoA4}
                                    onImprimirCrediario={imprimirCrediario}
                                    onDownloadXML={downloadXML}
                                    onVisualizar={onVisualizar}
                                />
                            }
                            {selectedItemref.current?.statusPedido === StatusPedido.ABERTO.toUpperCase() &&
                                <MenuItemAberto
                                    itemRef={selectedItemref}
                                    onCancelar={() => onOpen('showCancelarModal')}
                                    onNavigatePDV={navigatePdv}
                                    onEditarVendedor={editarVendedor}
                                    onImprimirPedido={imprimirPedido}
                                    onImprimirPedidoA4={imprimirPedidoA4}
                                    onImprimirCrediario={imprimirCrediario}
                                    onImprimirPreVenda={onImprimirPreVenda}
                                    onNavigatePreVenda={navigatePreVenda}
                                    onVisualizar={onVisualizar}
                                />
                            }
                            {selectedItemref.current?.statusPedido === StatusPedido.CANCELADO.toUpperCase() &&
                                <MenuItemCancelado
                                    itemRef={selectedItemref}
                                    onVisualizar={onVisualizar}
                                />
                            }
                        </Menu>

                    </TableBody>
                    {!props.totalizadoresError &&
                        <TableFooter
                            sx={{
                                width: '100%',
                                position: props.totalizadores.length >= 11 ? 'sticky' : 'absolute',
                                bottom: 0,
                                height: '40px',
                            }} className='glassMorphism'>
                            <TableRow>
                                <TableCell id="vendas_footer_quantidade" sx={{ paddingLeft: '50px', fontWeight: 'bold', color: '#000', fontSize: '14px', border: 0 }} colSpan={5}>Quantidade: {props.dadosFooter.quantidade}</TableCell>
                                {props.totalizadores.length < 11 &&
                                    <TableCell id="vendas_footer_espaco_quantidade_absolute" sx={{ width: '50%', border: 0 }}></TableCell>
                                }
                                <TableCell id="vendas_footer_total_pedido" sx={{ paddingLeft: '20px', fontWeight: 'bold', color: '#000', fontSize: '14px', border: 0 }} colSpan={3}>{currencyOf(props.dadosFooter.totalPedido)}</TableCell>
                                {props.totalizadores.length < 11 &&
                                    <TableCell id="vendas_footer_espaco_total_pedido_absolute" sx={{ width: '19%', border: 0 }}></TableCell>
                                }
                                <TableCell id="vendas_footer_total_nota" sx={{ paddingLeft: '20px', fontWeight: 'bold', color: '#000', fontSize: '14px', border: 0 }}>{currencyOf(props.dadosFooter.totalNota)}</TableCell>
                            </TableRow>
                        </TableFooter>
                    }
                </Table>
            </TableContainer>
            {stateLocal.showCancelarModal &&
                <CancelaPedidoDialog
                    onCloseCancelarModal={() => { onClose('showCancelarModal'); }}
                    itemRef={selectedItemref}
                    onCancelaPedido={props.onCancelaPedido}
                />
            }
            {stateLocal.showCancelarDFeDialog &&
                <CancelaDFeDialog
                    onClose={() => { onClose('showCancelarDFeDialog'); }}
                    itemRef={selectedItemref}
                    onAtualizarRelatorio={props.onAtualizarRelatorio}
                />
            }
            {stateLocal.loading &&
                <CustomLoading />
            }
            {stateLocal.showEditarVendedor &&
                <EditaVendedor
                    itemRef={selectedItemref}
                    onClose={() => onClose("showEditarVendedor")}
                    onEditaVendedor={onEditaVendedor}
                />
            }
            {stateLocal.isChangingVendedor &&
                <CustomLoading />
            }
        </>
    );
};

export default VendasTable;
